import { RouteLocation, createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'base',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        alias: [''],
      },
      {
        path: 'activity',
        children: [
          {
            path: '',
            name: 'activity',
            component: () => import('@/views/activity/Activity.vue'),
          },
          {
            path: ':activityId',
            name: 'activity-detail',
            component: () => import('@/views/activity/ActivityDetail.vue'),
          },
          {
            path: 'quiz/:activityId/:subactivityId',
            name: 'activity-quiz',
            component: () => import('@/views/activity/Quiz.vue'),
          },
          {
            path: 'quiz/result/:activityId/:subactivityId',
            name: 'activity-quiz-result',
            component: () => import('@/views/activity/QuizResult.vue'),
          },
        ],
      },
      {
        path: 'learn',
        children: [
          {
            path: '',
            name: 'learn',
            component: () => import('@/views/learn/Learn.vue'),
            alias: ['study'],
          },
          {
            path: 'summary/:learnId',
            name: 'learn-summary',
            component: () => import('@/views/learn/Summary.vue'),
            alias: ['lesson/:learnId/summary'],
          },
        ],
      },
      {
        path: 'lesson/:learnId/summary',
        redirect: (to: RouteLocation) => {
          return { name: 'learn-summary', params: { learnId: to.params.learnId } };
        },
      },
      {
        path: 'practice',
        name: 'practice',
        component: () => import('@/views/Practice.vue'),
        alias: ['practices'],
      },
      {
        path: 'aigc',
        name: 'aigc',
        component: () => import('@/views/AIGC.vue'),
        alias: ['AIGC'],
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('@/views/About.vue'),
      },
    ],
    component: () => import('@/views/Base.vue'),
  },
  {
    path: '/learn/content/:learnId/:contentId?',
    name: 'learn-content',
    component: () => import('@/views/learn/Content.vue'),
    alias: ['/lesson/:learnId/details/:contentId?'],
  },
  {
    path: '/invite/:pathMatch(.*)*',
    name: 'invite',
    component: () => import('@/views/invite/Index.vue'),
  },
  {
    path: '/activity/:activityId/:subactivityId/:taskId',
    name: 'task-detail',
    component: () => import('@/views/activity/TaskDetail.vue'),
  },
  {
    path: '/refresh',
    name: 'refresh',
    component: () => import('@/views/Refresh.vue'),
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/views/NotFound.vue') },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
